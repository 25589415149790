.Options {
    transition: all 0.1s;
    background-color: white;
    border: 0.2rem solid #555;
    border-radius: 0.3rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    cursor: pointer;
    z-index: 10;
}

.Options div {
    border-left: 1px solid black;
}

.Options div:first-child {
    border-left: 0;
}

.Options div:hover {
    background-color: #EEE;
}

.Options div:active {
    background-color: #DDD;
}

.OptionsPopup {
    transition: all 0.1s;
    background-color: white;
    border: 0.2rem solid #555;
    border-radius: 0.3rem;
    position: absolute;
    top: 4rem;
    left: 1rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 10;
    padding: 0.5rem 1.5rem;
    max-width: calc(100vw - 5.4rem);
}

.OptionsPopup *:first-child {
    align-self: center;
}

.OptionsPopup h3, h4 {
    margin-bottom: 0;
    margin-top: 0.5rem;
}

.Expeditions {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: scroll;
    flex-direction: column;
}

.ExpeditionsWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}

.Card {
    margin: 0.5rem;
    text-align: center;
    display: flex;
    cursor: pointer;
}

.Card img {
    max-width: 12rem;
}

.Card .Text {
    transition: all 0.1s;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    flex-grow: 1;
    background-color: white;
}

.Card .Text .viewMap {
    border: 0.2rem solid lightgrey;
    border-left: 0;
    border-bottom: 0;
    border-top-right-radius: 0.5rem;
    padding: 0 2rem;
    flex-grow: 1;
    margin: 0;
}

.Card .Text button {
    border: 0;
    padding: 0.5rem 2rem;
    background-color: #a590ec;
    overflow: hidden;
    flex-grow: 0;
    align-self: stretch;
    border-bottom-right-radius: 2rem;
}

.Card .Text .viewMap:hover {
    background-color: #EEE;
}

.Card .Text .viewMap:active {
    background-color: #DDD;
}

.Card .Text button:hover {
    background-color: #9176e7;
}

.Card .Text button:active {
    background-color: #7754ec;
}

.NoTransition * {
    transition: none !important;
}
