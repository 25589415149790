.GallCont {
    height: 100%;
}

.GallCont .Gallery {
    height: 100%;
    flex-grow: 1;
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    overflow-y: scroll;
    gap: 1rem 1rem;
    padding: 1rem;
}

.GallCont .Gallery .ImageBox {
    display: flex;
    flex-direction: column;

}

.GallCont .Gallery .ImageBox img {
    height: min(10rem, 20vw);
}

